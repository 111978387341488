import React from "react";
import NavBar from "./NavBar";
import Slideshow from "./Slideshow";
import FocusAndCriteria from "./FocusAndCriteria";
import WhatWeDo from "./WhatWeDo";
import Leadership from "./Leadership";
import News from "./News";
import ActiveCompanies from "./ActiveCompanies";
import ExitCompanies from "./ExitCompanies";
import Affiliations from "./Affiliations";
import Footer from "./Footer";

function Home(){
    return(
        <>
            <NavBar />
            <Slideshow />
            <FocusAndCriteria />
            <WhatWeDo />
            <Leadership />
            <News />
            <ActiveCompanies />
            <ExitCompanies />
            <Affiliations />
            <Footer />
        </>
    );
}

export default Home;