import React from "react";
import location from "../img/icons/location.png";
import team from "../img/icons/team.png";
import coach from "../img/icons/coach.png";
import tech from "../img/icons/technology.png";
import growth from "../img/icons/growth.png";

function FocusAndCriteria(){
    return (
        <div className="focus-and-criteria" id="focus-and-criteria">
            <div className="background-pic"></div>
            <h1>Investment Focus and Criteria</h1>
            <p id="text">Brilliant Keypoint Investments is primarily focused on companies in the technology and healthcare space in the DC Metro Area.</p>
            <div className="bullets-container">
                <div className="bullet" id="location">
                    <div className="bullet-icon">
                        <img className="icon" src={location} alt="Location" />
                    </div>
                    <div className="bullet-text-box">
                        <p className="bullet-text">USA companies located in the mid-Atlantic region</p>
                    </div>
                </div>
                <div className="bullet" id="team">
                    <div className="bullet-icon">
                        <img className="icon" src={team} alt="Team" />
                    </div>
                    <div className="bullet-text-box">
                        <p className="bullet-text">Complete management team with domain expertise</p>
                    </div>
                </div>
                <div className="bullet" id="coach">
                    <div className="bullet-icon">
                        <img className="icon" src={coach} alt="Coach" />
                    </div>
                    <div className="bullet-text-box">
                        <p className="bullet-text">Coachable management team</p>
                    </div>
                </div>
                <div className="bullet" id="technology">
                    <div className="bullet-icon">
                        <img className="icon" src={tech} alt="Technology" />
                    </div>
                    <div className="bullet-text-box">
                        <p className="bullet-text">Software-based technology product with a scalable business model</p>
                    </div>
                </div>
                <div className="bullet" id="growth">
                    <div className="bullet-icon">
                        <img className="icon" src={growth} alt="Growth" />
                    </div>
                    <div className="bullet-text-box">
                        <p className="bullet-text">Paying customers with a realistic plan for rapid growth</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FocusAndCriteria;