
export const displayText = ( logo_id ) => {
    const logo = document.getElementById( logo_id + "-text" );
    if( logo ){
        logo.style.display = "initial";
    } 
}

export const hideText = ( logo_id ) => {
    const logo = document.getElementById( logo_id + "-text" );
    if( logo ){
        logo.style.display = "none";
    }
}