import React from "react";
import logos from "./Affiliation-Images";
import {displayText, hideText} from "./Hover";

function Affiliations(){
    return(
        <>
        <h1 id="affil">Affiliations</h1>
        <div className="affiliations companies">
            <a className="portfolio-link" href="https://www.bluventureinvestors.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("blu")}} onMouseLeave={() => {hideText("blu")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[0]} />
                    </div>
                    <div className="logo logo-text logo-width" id="blu-text">
                        <h3>Blu Venture</h3>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.mach37.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("mach")}} onMouseLeave={() => {hideText("mach")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[1]} />
                    </div>
                    <div className="logo logo-text logo-width" id="mach-text">
                        <h3>Mach37</h3>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://nextgenvp.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("next")}} onMouseLeave={() => {hideText("next")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[2]} />
                    </div>
                    <div className="logo logo-text logo-width" id="next-text">
                        <h3>NextGen Venture Partners</h3>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="http://www.newdominionangels.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("angels")}} onMouseLeave={() => {hideText("angels")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[3]} />
                    </div>
                    <div className="logo logo-text logo-width" id="angels-text">
                        <h3>New Dominion Angels</h3>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="http://www.bluejaysyndicate.org/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("blue")}} onMouseLeave={() => {hideText("blue")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[4]} />
                    </div>
                    <div className="logo logo-text logo-width" id="blue-text">
                        <h3>Blue Jay Syndicate</h3>
                    </div>
                </div>
            </a>
        </div>
        </>
    );
}

export default Affiliations;