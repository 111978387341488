import React from "react";
import linkedin from "../img/icons/linkedin.png";

function Leadership(){
    function Display( displayId, hideId ){
        const sele = document.getElementById( displayId );
        const show = document.getElementById( displayId + "-text" );
        const hide = document.getElementById( hideId );
        if( show && hide ){
            sele.style.display = "flex";
            show.style.display = "initial";
            show.style.backgroundColor = "white";
            show.style.color = "#667479";
            hide.style.display = "none";
        }
    }

    function Hide( displayId, hideId ){
        const show = document.getElementById( displayId + "-text" );
        const hide = document.getElementById( hideId );
        if( show && hide ){
            show.style.display = "none";
            hide.style.display = "initial";
        }
    }

    return(
        <div className="leadership" id="leadership">
            <div className="background-pic"></div>
            <h1>Leadership</h1>
            <p id="text">Our leadership team at Brilliant Keypoint Investments, LLC is dedicated to partnering with entrepreneurs.  Our decades of business experience include go to market strategies, company scaling, culture change, leadership development, and corporate technology management.  We can also help with pitch development and fundraising. 
            </p>
            <div className="profile-container">
                <div className="profile" id="ben" onMouseEnter={() => {Display("ben", "kim")}} onMouseLeave={() => {Hide("ben", "kim")}}>
                    <div className="top" id="ben-pic">
                        <div className="nametag">
                            <h3>Dr. Ben Nguyen</h3>
                            <p>Managing Partner</p>
                        </div>
                    </div>
                    <div className="profile-text" id="ben-text">
                        <p>
                        Dr. Ben Nguyen is a board-certified in both Neurosurgery and Clinical Informatics.  Through his practice (managing partner of Atlantic Brain & Spine, LLC), he had 15+ years of experience in the use of surgical technologies, clinical research, and EHR use.
                        </p>
                        <p>
                            Dr. Nguyen currently leads the Pragmatics Technical Solutions Group (TSG) and works with Pragmatics’ leadership team to develop innovative solutions.  He also currently directs a machine learning project leads a team of developers to prototype new solutions for analytics using convolutional neural networks and other algorithms. Dr. Nguyen and his team actively research new technologies for application to a machine learning platform designed to orchestrate multiple algorithms to solve complex data problems.
                        </p>
                        <a href="https://www.linkedin.com/in/ben-nguyen-md-9501a876/" targe="_blank">
                            <img className="linkedin-icon" src={linkedin} alt="LinkedIn" />
                            LinkedIn
                        </a>
                    </div>
                </div>
                <div className="profile" id="kim" onMouseEnter={() => {Display("kim", "ben")}} onMouseLeave={() => {Hide("kim", "ben")}}>
                    <div className="profile-text" id="kim-text">
                        <p>
                        Dr. Kim Nguyen led business development and growth in the Federal IT services industry for Pragmatics, Inc. for more than 15 years. During this time, Pragmatics grew more than 20 times to over $185M in revenue, while maintaining exceptional customer satisfaction and above industry profitability.
                        </p>
                        <p>Dr. Nguyen is a principal at Blu Venture Investors.  He has served on the Cyber Seed Fund Investment Committee, the Executive Committee, and numerous working groups to improve operations.  He has been a part of New Dominion Angels, Blue Jay Syndicate, and Nextgen Venture Partners.</p>
                        <a href="https://www.linkedin.com/in/kim-nguyen-b4412aa/" targe="_blank">
                            <img className="linkedin-icon" src={linkedin} alt="LinkedIn" />
                            LinkedIn
                        </a>
                    </div>
                    <div className="top" id="kim-pic">
                        <div className="nametag">
                            <h3>Dr. Kim Nguyen</h3>
                            <p>Managing Partner</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leadership;