import React from "react";
import logos from "./Exit-Images";
import {displayText, hideText} from "./Hover";

function ExitCompanies(){
    return(
        <>
        <h2 className="h2-logo"><span className="span-logo">Exit Companies</span></h2>
        <div className="exit-companies companies" id="exit">
            <a className="portfolio-link" href="https://www.linkedin.com/company/encore-hq/about/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("encore")}} onMouseLeave={() => {hideText("encore")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[0]} />
                    </div>
                    <div className="logo logo-text logo-width" id="encore-text">
                        <h3>EncoreAlert</h3>
                        <h4 className="year">2016</h4>
                        <p>The right social opportunities at the right time.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://business.amwell.com/avizia-acquisition/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("avizia")}} onMouseLeave={() => {hideText("avizia")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[1]} />
                    </div>
                    <div className="logo logo-text logo-width" id="avizia-text">
                        <h3>Avizia</h3>
                        <h4 className="year">2018</h4>
                        <p>Telemedicine.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.interfolio.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("interfolio")}} onMouseLeave={() => {hideText("interfolio")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[2]} />
                    </div>
                    <div className="logo logo-text logo-width" id="interfolio-text">
                        <h3>Interfolio</h3>
                        <h4 className="year">2019</h4>
                        <p>Products for the real workflows of higher ed.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.trackoff.com/en" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("track")}} onMouseLeave={() => {hideText("track")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[3]} />
                    </div>
                    <div className="logo logo-text logo-width" id="track-text">
                        <h3>TrackOFF</h3>
                        <h4 className="year">2019</h4>
                        <p>Privacy software that secures your identity and personal data online.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.linkedin.com/company/cybersponse-inc-" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("cybersponse")}} onMouseLeave={() => {hideText("cybersponse")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[4]} />
                    </div>
                    <div className="logo logo-text logo-width" id="cybersponse-text">
                        <h3>Cybersponse</h3>
                        <h4 className="year">2019</h4>
                        <p>Empowers Security Operations Teams to Achieve Higher Efficiencies and Accelera.</p>
                    </div>
                </div>
            </a>
        </div>
        </>
    );
}

export default ExitCompanies;