import aquicore_logo from "../img/logos/active/aquicore.png";
import atomicorp_logo from "../img/logos/active/atomicorp.png";
import attila_logo from "../img/logos/active/attila_security.jpg";
import bot_logo from "../img/logos/active/botdoc.png";
import cybrary_logo from "../img/logos/active/cybrary.png";
import emocha_logo from "../img/logos/active/emocha.png";
import group_logo from "../img/logos/active/groupsense.png";
import haas_logo from "../img/logos/active/HAAS_Alert.jpg";
import huntress_logo from "../img/logos/active/huntress.png";
import leprix_logo from "../img/logos/active/LePrix.png";
import link_logo from "../img/logos/active/link_labs.png";
import lkc_logo from "../img/logos/active/lkc-technologies.png";
import new_logo from "../img/logos/active/newconnect.png";
import open_logo from "../img/logos/active/open-health-network.jpg";
import ostendio_logo from "../img/logos/active/ostendio.jpeg";
import pfp_logo from "../img/logos/active/pfp_cybersecurity.png";
import racktop_logo from "../img/logos/active/racktop.jpeg";
import stream_logo from "../img/logos/active/stream-link.png";
import street_logo from "../img/logos/active/street-shares.png";
import threat_logo from "../img/logos/active/threat_quotient.png";
import van_logo from "../img/logos/active/van-gogh-imaging.png";
// import from "../img/logos/active/";


export default [
    aquicore_logo,
    atomicorp_logo,
    attila_logo,
    bot_logo,
    cybrary_logo,
    emocha_logo,
    group_logo,
    haas_logo,
    huntress_logo,
    leprix_logo,
    link_logo,
    lkc_logo,
    new_logo,
    open_logo,
    ostendio_logo,
    pfp_logo,
    racktop_logo,
    stream_logo,
    street_logo,
    threat_logo,
    van_logo
];