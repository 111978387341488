import React from "react";

function Footer(){
    return(
        <div className="footer">
            <p>&copy; {(new Date().getFullYear())} Brilliant Keypoint Investments, LLC.</p>
        </div>
    );
}

export default Footer;