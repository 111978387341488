import './App.css';
import './css/NavBar.css';
import './css/Slideshow.css';
import './css/FocusAndCriteria.css';
import './css/WhatWeDo.css';
import './css/Leadership.css';
import './css/News.css';
import './css/Logos.css';
import './css/Footer.css';
import Home from "./sections/Components";
import NotFoundPage from "./sections/NotFoundPage";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact/>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
