import React, {useState, useEffect} from "react";
import logo from "../img/logos/logo.png";

function NavBar(){
    function MenuDropdown(){
        document.getElementById("links-container").classList.toggle("showMenu");
    }

    return(
        <div className="nav-bar">
            <div className="bk-logo-container">
                <a href="#slideshow"><img id="bk-logo" src={logo} /></a>
            </div>
            <div className="menu-dropdown-container">
                <button className="menu-dropdown-btn" onClick={()=>MenuDropdown()}>Menu</button>
                <div className="links-container" id="links-container" onClick={()=>MenuDropdown()}>
                    <a href="#focus-and-criteria" className="link lpad">Focus and Criteria</a>
                    <a href="#what-we-do" className="link lpad">What We Do</a>
                    <a href="#leadership" className="link lpad">Leadership</a>
                    <a href="#news" className="link lpad">News</a>
                    <div className="portfolio-dropdown-container">
                        <button className="link lpad portfolio-dropdown-btn" id="portfolio">Porfolio Companies</button>
                        <div className="portfolio-links">
                            <a href="#active" className="item lpad">Active Companies</a>
                            <a href="#exit" className="item lpad">Exit Companies</a>
                        </div>
                    </div>
                    <a href="#active" className="item lpad small-screen">Active Companies</a>
                    <a href="#exit" className="item lpad small-screen">Exit Companies</a>
                    <a href="#affil" className="link">Affiliations</a>
                </div>
            </div>
        </div>
    );
}

export default NavBar;