import React from "react";
import logos from "./Active-Images";
import {displayText, hideText} from "./Hover";


function ActiveCompanies(){
    return(
        <>
        <h1 className="portfolio">Portfolio Companies</h1>
        <h2 className="h2-logo" id="active"><span className="span-logo">Active Companies</span></h2>
        <div className="active-companies companies">
            <a className="portfolio-link" href="https://aquicore.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("aquicore")}} onMouseLeave={() => {hideText("aquicore")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[0]} />
                    </div>
                    <div className="logo logo-text logo-width" id="aquicore-text">
                        <h3>Aquicore</h3>
                        <p>A portfolio-wide Energy Management Solution.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://atomicorp.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("attomicorp")}} onMouseLeave={() => {hideText("attomicorp")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[1]} />
                    </div>
                    <div className="logo logo-text logo-width" id="attomicorp-text">
                        <h3>Atomicorp</h3>
                        <p>Expanding the power and functionality of Open Source Security to your organization.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.attilasec.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("attila")}} onMouseLeave={() => {hideText("attila")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[2]} />
                    </div>
                    <div className="logo logo-text logo-width" id="attila-text">
                        <h3>Attila Security</h3>
                        <p>Protect sensitive enterprise data by allowing it to be accessed from any location via an IP enabled device.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.botdoc.io/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("botdoc")}} onMouseLeave={() => {hideText("botdoc")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[3]} />
                    </div>
                    <div className="logo logo-text logo-width" id="botdoc-text">
                        <h3>Botdoc</h3>
                        <p>Remote secure file transfer that combines end-to-end encryption with a seamless consumer experience.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.cybrary.it/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("cybrary")}} onMouseLeave={() => {hideText("cybrary")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[4]} />
                    </div>
                    <div className="logo logo-text logo-width" id="cybrary-text">
                        <h3>Cybrary</h3>
                        <p>Brining all of the best cybersecurity learning elements and content together.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://emocha.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("emocha")}} onMouseLeave={() => {hideText("emocha")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[5]} />
                    </div>
                    <div className="logo logo-text logo-width" id="emocha-text">
                        <h3>eMocha</h3>
                        <p>A mobile health platform for remote patient management.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.groupsense.io/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("groupsense")}} onMouseLeave={() => {hideText("groupsense")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[6]} />
                    </div>
                    <div className="logo logo-text logo-width" id="groupsense-text">
                        <h3>Groupsense</h3>
                        <p>Monitoring the Dark Web to alert clients to external cyber threats specific to their organization.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.haasalert.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("haas")}} onMouseLeave={() => {hideText("haas")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[7]} />
                    </div>
                    <div className="logo logo-text logo-width" id="haas-text">
                        <h3>HaaS Alert</h3>
                        <p>Collision Prevention for Emergency Fleets and Road Operators.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.huntress.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("huntress")}} onMouseLeave={() => {hideText("huntress")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[8]} />
                    </div>
                    <div className="logo logo-text logo-width" id="huntress-text">
                        <h3>Huntress</h3>
                        <p>End point agent for MSPs.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://leprix.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("leprix")}} onMouseLeave={() => {hideText("leprix")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[9]} />
                    </div>
                    <div className="logo logo-text logo-width" id="leprix-text">
                        <h3>LePrix</h3>
                        <p>Online Consignment for authentic Designer Fashion Brands.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.link-labs.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("link")}} onMouseLeave={() => {hideText("link")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[10]} />
                    </div>
                    <div className="logo logo-text logo-width" id="link-text">
                        <h3>Link Labs</h3>
                        <p>Wide area, Low Power Networks for the Internet of Things.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://lkc.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("lkc")}} onMouseLeave={() => {hideText("lkc")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[11]} />
                    </div>
                    <div className="logo logo-text logo-width" id="lkc-text">
                        <h3>LKC</h3>
                        <p>Manuffacturer of medical devices that provide fast, low cost, mobile screening for diabetic retinopathy.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://gonewconnect.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("new")}} onMouseLeave={() => {hideText("new")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[12]} />
                    </div>
                    <div className="logo logo-text logo-width" id="new-text">
                        <h3>NewConnect</h3>
                        <p>Wireless Fiber that powers the last mile of Internet access to enterprises and buildings.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.openhealth.cc/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("open")}} onMouseLeave={() => {hideText("open")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[13]} />
                    </div>
                    <div className="logo logo-text logo-width" id="open-text">
                        <h3>Open Health Network</h3>
                        <p>A smart Mobile Health Platform.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.ostendio.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("ostendio")}} onMouseLeave={() => {hideText("ostendio")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[14]} />
                    </div>
                    <div className="logo logo-text logo-width" id="ostendio-text">
                        <h3>Ostendio</h3>
                        <p>Integrated Risk Management Software and Services</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.pfpcyber.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("pfp")}} onMouseLeave={() => {hideText("pfp")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[15]} />
                    </div>
                    <div className="logo logo-text logo-width" id="pfp-text">
                        <h3>PFP Cybersecurity</h3>
                        <p>Powering Fingerprinting (PFP)- a unique approach to cybersecurity.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.racktopsystems.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("racktop")}} onMouseLeave={() => {hideText("racktop")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[16]} />
                    </div>
                    <div className="logo logo-text logo-width" id="racktop-text">
                        <h3>Racktop</h3>
                        <p>Data storage with advanced security and compliance on one platform.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.amplifund.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("stream")}} onMouseLeave={() => {hideText("stream")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[17]} />
                    </div>
                    <div className="logo logo-text logo-width" id="stream-text">
                        <h3>StreamLink Software</h3>
                        <p>Software Solutions for Nonprofit Organizations and the Public Sector.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.streetshares.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("street")}} onMouseLeave={() => {hideText("street")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[18]} />
                    </div>
                    <div className="logo logo-text logo-width" id="street-text">
                        <h3>StreetShares</h3>
                        <p>Marketplace lender for Small Business Loans.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.threatq.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("threat")}} onMouseLeave={() => {hideText("threat")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[19]} />
                    </div>
                    <div className="logo logo-text logo-width" id="threat-text">
                        <h3>Threat Quotient</h3>
                        <p>A Threat Intelligence Platform (TIP) that streamlines, correlates and analyzes critical threat intelligence from across the globe.</p>
                    </div>
                </div>
            </a>
            <a className="portfolio-link" href="https://www.vangoghimaging.com/" target="_blank">
                <div className="logo-container logo-width" onMouseEnter={() => {displayText("van")}} onMouseLeave={() => {hideText("van")}}>
                    <div className="logo logo-img log-width">
                        <img className="logo-width" src={logos[20]} />
                    </div>
                    <div className="logo logo-text logo-width" id="van-text">
                        <h3>VanGogh Imaging</h3>
                        <p>The Most Advanced 3D Capture Software for AR/VR.</p>
                    </div>
                </div>
            </a>
        </div>
        </>
    );
}

export default ActiveCompanies;