import React, {useState, useEffect} from "react";
import images from "./Slideshow-Images";

function Slideshow(){
    const [counter, setCounter] = useState(0);
    const slides = document.getElementsByClassName("slideshow-container");
    const dots = document.getElementsByClassName("dot");

    useEffect( () => {
        if( counter < 0 ){
            setCounter( 4 );
        }
        if( counter > 4 ){
            setCounter( 0 );
        }
        for( var i = 0; i < slides.length; i++ ){
            if( counter != i ){
                slides[ i ].style.display = "none";
                dots[ i ].classList.remove( "active");
            }
        }
        if( counter >= 0 && counter <= 4 ){
            slides[ counter ].style.display = "grid";
            slides[ counter ].style.gridTemplateColumns = "repeat( 3, auto )";
            slides[ counter ].style.position = "relative";
            slides[ counter ].style.gridRow = "1 / 6";
            slides[ counter ].style.gridColumn = "1";
            slides[ counter ].style.zIndex = "-1";
            dots[ counter ].classList.add( "active" );
        }
    }, [counter]);

    return(
        <div className="slideshow" id="slideshow">
            <div className="slideshow-container">
                <div className="image-container">
                    <img className="slideshow-image" src={images[0]} />
                </div>
                <div className="top-container">
                    <div className="text-container">
                        <h1 className="slideshow-title color">Experience</h1>
                        <p className="slideshow-text color">Drawing upon more than 20 years of business and medical experience.</p>
                    </div>
                </div>
            </div>
            <div className="slideshow-container">
                <div className="image-container">
                    <img className="slideshow-image original-img" src={images[1]} />
                </div>
                <div className="top-container">
                    <div className="text-container">
                        <h1 className="slideshow-title color">Local</h1>
                        <p className="slideshow-text color">Working with companies in the DC Metro Area.</p>
                    </div>
                </div>
            </div>
            <div className="slideshow-container">
                <div className="image-container">
                    <img className="slideshow-image" src={images[2]} />
                </div>
                <div className="top-container">
                    <div className="text-container">
                        <h1 className="slideshow-title color">Vision</h1>
                        <p className="slideshow-text color">Helping early-stage entrepreneurs realize their vision.</p>
                    </div>
                </div>
            </div>
            <div className="slideshow-container">
                <div className="image-container">
                    <img className="slideshow-image original-img" src={images[3]} />
                </div>
                <div className="top-container">
                    <div className="text-container">
                        <h1 className="slideshow-title color">Scaling</h1>
                        <p className="slideshow-text color">Rapidly scaling tech-enabled B2B, cybersecurity, and healthcare solutions.</p>
                    </div>
                </div>
            </div>
            <div className="slideshow-container">
                <div className="image-container">
                    <img className="slideshow-image original-img" src={images[4]} />
                </div>
                <div className="top-container">
                    <div className="text-container">
                        <h1 className="slideshow-title color">Outcomes</h1>
                        <p className="slideshow-text color">Driving business outcomes through leadership and structured processes.</p>
                    </div>
                </div>
            </div>

            <div className="button-container">
                <button className="slideshow-button" onClick={() => {setCounter( counter - 1)}}>&#10094;</button>
                <button className="slideshow-button" onClick={() => {setCounter( counter + 1)}}>&#10095;</button>
            </div>
            <div className="dot-buttons-container">
                <span className="dot" onClick={() => {setCounter( 0 )}}/>
                <span className="dot" onClick={() => {setCounter( 1 )}}/>
                <span className="dot" onClick={() => {setCounter( 2 )}}/>
                <span className="dot" onClick={() => {setCounter( 3 )}}/>
                <span className="dot" onClick={() => {setCounter( 4 )}}/>
            </div>
        </div>
    );
}

export default Slideshow;