import React from "react";
import invest from "../img/cards/invest.jpg";
import inspire from "../img/cards/inspire.jpg";
import empower from "../img/cards/motivation.jpg";


function WhatWeDo(){
    return(
        <div className="what-we-do" id="what-we-do">
            <h1>What We Do</h1>
            <p id='wwd-p'>At Brilliant Keypoint Investments, LLC., we seek to invest, inspire, and empower early-stage entrereneurs. We want to realize your vision by combining exceptional management teams, disruptive technology and high customer satisfaction to yield explosive corporate growth. As business owners ourselves, we understand the challenges in developing and growing a sustainable business. We provide capital, operational advice, and access to our network, drawn from more than 20 years of experience in the technology and healthcare sectors.</p>
            <div className="cards-container">
            {/*
            Copyright (&copy;) 2021 by Saravana Kumar (https://codepen.io/SaroDiwa/pen/pWeWdP)
            Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
            The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
            THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.    
            */}
                <figure className="snip1543">
                    <img src={invest} />
                    <figcaption>
                        <h3>Investing in the Future</h3>
                        <p>We invest in transformative technologies to improve patient outcomes and people’s quality of life.</p>
                    </figcaption>
                    <a href="#" onClick={(e) => {e.preventDefault();}}></a>
                </figure>
                <figure className="snip1543">
                    <img src={inspire} />
                    <figcaption>
                        <h3>Inspiring today's tomorrow</h3>
                        <p>Dynamic leadership and engaged employees deliver superior results to clients and shareholders.</p>
                    </figcaption>
                    <a href="#" onClick={(e) => {e.preventDefault();}}></a>
                </figure>
                <figure className="snip1543">
                    <img src={empower} />
                    <figcaption>
                        <h3>Empowering Advancements</h3>
                        <p>Empowered teams can flexibly adapt to market and technology changes.</p>
                    </figcaption>
                    <a href="#" onClick={(e) => {e.preventDefault();}}></a>
                </figure>
            </div>
        </div>
    );
}

export default WhatWeDo;